<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="staffList"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getStaffTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Персонал</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on"> Додати співробітника </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row class="align-center">
                      <v-col v-if="editedIndex === -1" cols="12" sm="6" md="4">
                        <v-autocomplete
                          ref="autocomplete"
                          v-model="userPhone"
                          :search-input.sync="phoneInput"
                          :items="phoneItems"
                          :loading="usersLoading"
                          item-value="id"
                          item-text="phone"
                          label="Телефон співробітника"
                          @focus="prefillPhone"
                          @input="$v.userPhone.$touch()"
                          @keyup="handleKeyup"
                          @blur="$v.userPhone.$touch()"
                          @change="phoneSelected"
                          :error-messages="userPhonesErrors"
                        >
                          <template v-slot:append-item>
                            <div
                              v-intersect.quiet="{
                                handler: onIntersect,
                                options: {
                                  threshold: 1,
                                },
                              }"
                              class="text-center grey--text"
                            >
                              <span v-show="loading">Завантаження ...</span>
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col v-if="editedIndex === -1" cols="12" sm="6" md="4">
                        <span v-if="userSelected">{{ userSelected.name }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model.number="editedItem.position.id"
                          :items="positions"
                          item-text="name"
                          item-value="id"
                          label="Посада"
                          @input="$v.editedItem.position.id.$touch()"
                          @blur="$v.editedItem.position.id.$touch()"
                          :error-messages="positionsErrors"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menuStartCooperationDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.start_cooperation"
                              label="Початок співпраці"
                              prepend-icon="mdi-calendar"
                              hint="рiк-місяць-число"
                              persistent-hint
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @input="$v.editedItem.start_cooperation.$touch()"
                              @blur="$v.editedItem.start_cooperation.$touch()"
                              :error-messages="startCooperationErrors"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.start_cooperation"
                            @input="menuStartCooperationDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col v-if="editedIndex > -1" cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menuStopCooperationDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.stop_cooperation"
                              label="Закінчення співпраці"
                              prepend-icon="mdi-calendar"
                              hint="рiк-місяць-число"
                              clearable
                              persistent-hint
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.stop_cooperation"
                            @input="menuStopCooperationDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Співробітника буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{ name: 'StaffCard', params: { id: currentCompanyId, staffId: item.id } }"
            class="text-decoration-none"
          >
            {{ item.user.id }}
          </router-link>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{ name: 'StaffCard', params: { id: currentCompanyId, staffId: item.id } }"
            class="text-decoration-none"
          >
            {{ item.user.first_name }} {{ item.user.last_name }}
          </router-link>
        </template>
        <template v-slot:item.start_cooperation="{ item }">{{ item.start_cooperation | getShortDate }}</template>
        <template v-slot:item.stop_cooperation="{ item }">{{ item.stop_cooperation | getShortDate }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити з персоналу цієї компанії</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToEmployeeCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { STAFF_SCHEMA } from '@/const/apiSchemas'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import cloneDeep from '@/mixins/cloneDeep'
import convertDate from '@/mixins/convertDate'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import resetTableData from '@/mixins/resetTableData'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'StaffList',
  components: { BackButton },

  mixins: [validationMixin, resetTableData, cloneDeep, convertDate],

  validations: {
    editedItem: {
      position: {
        id: { required },
      },
      start_cooperation: { required },
    },
    userPhone: { required },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    dialog: false,
    dialogDelete: false,
    search: '',
    loading: false,
    clickedStaffId: null,
    menuStartCooperationDate: false,
    menuStopCooperationDate: false,
    userPhone: '',
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: "Повне ім'я", value: 'name', sortable: false },
      { text: 'Посада', value: 'position.name', sortable: false },
      { text: 'Початок співпраці', value: 'start_cooperation', sortable: false },
      { text: 'Закінчення співпраці', value: 'stop_cooperation', sortable: false },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '125px',
        align: 'center',
      },
    ],
    staffList: [],
    producerList: ['Vending.Land', 'Runolfsson, Hartmann and Schroeder'],
    editedIndex: -1,
    options: {},
    editedItem: {},
    defaultItem: {},
    phoneInput: '',
    phonePrefix: '+380',
    phoneItems: null,
    phoneItemsMeta: null,
    usersLoading: false,
    userSelected: '',
    inputTimeout: null,
  }),

  computed: {
    ...mapGetters('staff', ['getStaffTotalCount']),
    ...mapState('staff', ['staff']),
    ...mapState('users', ['users', 'usersMeta']),
    ...mapState('positions', ['positions']),

    formTitle() {
      return this.editedIndex === -1 ? 'Додати співробітника' : 'Редагувати співробітника'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    userPhonesErrors() {
      const errors = []
      if (!this.$v.userPhone.$dirty) return errors
      !this.$v.userPhone.required && errors.push('Це поле обов"язкове')
      return errors
    },
    positionsErrors() {
      const errors = []
      if (!this.$v.editedItem.position.id.$dirty) return errors
      !this.$v.editedItem.position.id.required && errors.push('Це поле обов"язкове')
      return errors
    },
    startCooperationErrors() {
      const errors = []
      if (!this.$v.editedItem.start_cooperation.$dirty) return errors
      !this.$v.editedItem.start_cooperation.required && errors.push('Це поле обов"язкове')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  created() {
    this.loadData()
  },

  mounted() {
    // this.clone()
  },

  methods: {
    ...mapActions('staff', ['loadStaff', 'createNewEmployee', 'updateSelectedEmployee', 'deleteSelectedEmployee']),
    ...mapActions('users', ['loadUsers']),
    ...mapActions('positions', ['loadAllPositions']),
    ...mapActions('logs', ['displayWarningAlert']),

    async loadData() {
      this.clone()
      await Promise.all([this.loadAllPositions(this.currentCompanyId)])
    },

    clone() {
      this.editedItem = this.cloneObjectDeep(STAFF_SCHEMA)
      this.defaultItem = this.cloneObjectDeep(STAFF_SCHEMA)
    },

    initialize() {
      this.staffList = [...this.staff]
    },

    prefillPhone() {
      if (!this.phoneInput) this.phoneInput = this.phonePrefix
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          company: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        }
        await this.loadStaff(params)
        await this.initialize()
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберiть компанiю' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      this.editedIndex = this.staffList.indexOf(item)
      this.editedItem = mergeObjectsDeep(this.editedItem, item)
      this.clickedStaffId = item.id
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.staffList.indexOf(item)
      this.clickedStaffId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        staffId: this.clickedStaffId,
      }
      await this.deleteSelectedEmployee(payload)
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.phoneItems = []
        this.phoneItemsMeta = null
        this.usersPage = 1
        this.userPhone = ''
        this.userSelected = null
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      this.$v.editedItem.$touch()
      if (this.editedIndex === -1) this.$v.userPhone.$touch()
      if (this.$v.$anyError) return
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          companyId: this.currentCompanyId,
          staffId: this.clickedStaffId,
          updatedEmployee: this.editedItem,
        }
        await this.updateSelectedEmployee(payload)
      } else {
        delete this.editedItem.stop_cooperation
        const payload = {
          companyId: this.currentCompanyId,
          newEmployee: {
            ...this.editedItem,
            user: { id: this.userSelected.id },
          },
        }
        await this.createNewEmployee(payload)
      }
      this.close()
      await this.paginateTo()
      this.loading = false
    },

    navigateToEmployeeCard(item) {
      const clickedStaffId = item.id
      this.$router.push({
        name: 'StaffCard',
        params: { id: this.currentCompanyId.toString(), staffId: clickedStaffId.toString() },
      })
    },

    phoneSelected(item) {
      const user = this.phoneItems.find((phoneItem) => phoneItem.id === item)
      if (user) {
        this.userSelected = {
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
        }
      } else {
        this.userSelected = null
      }
    },

    handleKeyup(event) {
      const { value } = event?.target || ''
      if (this.inputTimeout) {
        clearTimeout(this.inputTimeout)
        this.inputTimeout = null
      }
      this.inputTimeout = setTimeout(async () => {
        this.usersLoading = true
        this.usersPage = 1
        const payload = {
          companyId: this.currentCompanyId,
          search: value,
          sort: {
            phone: 'asc',
          },
          forSearch: 1,
          page: this.usersPage,
        }
        await this.loadUsers(payload)
        this.phoneItems = [...this.users]
        this.phoneItemsMeta = { ...this.usersMeta }
        this.usersLoading = false
      }, 500)
    },

    async onIntersect(entries) {
      if (entries[0].intersectionRatio === 1 && this.usersPage < this.phoneItemsMeta?.total_pages) {
        this.usersLoading = true
        this.usersPage++
        const payload = {
          companyId: this.currentCompanyId,
          search: this.phoneInput,
          sort: 'phone',
          direction: 'asc',
          forSearch: 1,
          page: this.usersPage,
        }
        await this.loadUsers(payload)
        this.$set(this.phoneItems, this.phoneItems.length, this.users)
        this.phoneItems = [...this.phoneItems.flat()]
        this.usersLoading = false
      }
    },
  },
}
</script>

<style scoped></style>
